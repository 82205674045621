.person-summary {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.person-summary.inactive {
  opacity: 0.7;
}

.person-image svg {
  font-size: 50px;
}

.person-image {
  margin-right: 30px;
}

.person-name h2 {
  font-size: 26px;
  font-weight: 300;
  margin-right: 20px;
}

.person-summary .nps-color-circle {
  width: 28px;
  height: 28px;
}

.person-summary .nps-color-text {
  font-size: 24px;
  vertical-align: middle;
}

.person-desc {
  display: flex;
}

.person-desc-left,
.person-desc-right {
  flex-grow: 1;
}

.person-desc-single-title {
  margin-right: 10px;
}

.person-desc-single {
  margin-top: 5px;
}

.person-action {
  float: right;
}

.person-active-btn {
  background-color: #0e83cd;
  color: #fff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.person-nps-cards {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
  .person-action {
    float: none;
    text-align: right;
  }

  .person-summary {
    flex-wrap: wrap;
  }

  .person-desc {
    flex-wrap: wrap;
  }

  .person-desc-right {
    margin-top: 20px;
  }

  .person-nps-cards {
    display: block;
  }

  .person-type {
    margin-top: 10px;
    width: 100%;
    text-align: right;
    margin-right: 20px;
  }

  .person-nps-cards .simple-card {
    margin-left: 0px;
  }
}
