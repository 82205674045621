.admin-add-btn {
  float: right;
}

.admin-add-btn .btn {
  font-size: 28px;
}

.admin-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px 0px;
}

.admin-label {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  display: inline-block;
}

.admin-options {
  text-align: right;
  width: 100px;
  float: right;
  padding: 0px;
  margin-top: -5px;
}

.admin-inactive {
  color: #ccc;
  border-color: #f3f3f3;
}

.admin-change-password-label {
  margin-bottom: 10px;
}

.admin-change-password .form-field {
  display: inline-block;
  width: 100%;
  max-width: 320px;
  min-height: 80px;
  vertical-align: middle;
}

.update-password-btn {
  display: block;
  margin: 10px 0px;
  background-color: #004359;
  color: #fff;
  padding: 10px 25px;
}

@media (max-width: 1024px) and (min-width: 768px),
  (max-width: 767px) and (min-width: 481px),
  (max-width: 480px) and (min-width: 0px) {
  .admin-label {
    display: block;
  }
}
