.custom-card-wrapper {
  margin: 8px 0px;
  flex-grow: 1;
}

.custom-card {
  min-height: 160px;
  max-height: 100%;
  height: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.custom-card > * {
  width: 100%;
}

.custom-card-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.custom-card-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  font: fallback;
  text-align: left;
}

.custom-card-value {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #212121;
  width: 100%;
  text-align: start;
}

.custom-card-subvalue {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  text-align: start;
  margin-top: 3px;
}

.custom-card-footer {
  width: 100%;
  display: flex;
}

.custom-card-footer-info {
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  text-align: start;
}
