.dashboard-cards-outer-wrapper {
  width: 100%;
}
.dashboard-cards-inner-wrapper {
  display: grid;
  grid-template-areas:
    'main main first second'
    'main main third fourth';
  grid-auto-rows: 1fr;
}
.dashboard-cards-inner-wrapper div:nth-child(1) {
  grid-area: main;
}
.dashboard-cards-inner-wrapper div:nth-child(2) {
  grid-area: first;
}
.dashboard-cards-inner-wrapper div:nth-child(3) {
  grid-area: second;
}
.dashboard-cards-inner-wrapper div:nth-child(4) {
  grid-area: third;
}
.dashboard-cards-inner-wrapper div:nth-child(5) {
  grid-area: fourth;
}
