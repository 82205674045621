.dropdown {
  min-width: 200px;
  width: 100%;
  max-width: 350px;
}

.dropdown .btn {
  width: 100%;
  margin: 0;
  padding: 11px;
  text-align: left;
}

.dropdown .icon-chev {
  float: right;
  transition: all ease-out 0.4s;
  font-size: 18px;
  color: #0e83cd;
}

.dropdown-select {
  min-width: 120px;
}

.dropdown .dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  border: 1px solid #0e83cd;
  width: 100%;
  top: 40px;
  left: -1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-content .btn {
  color: rgb(95, 95, 95);
  padding: 8px 16px;
}

.dropdown-content .btn:hover {
  background-color: #0e83cd;
  opacity: 1;
  color: #fff;
}

.dropdown.show .dropdown-content {
  display: block;
}

.dropdown.show:not(.search) .icon-chev {
  transform: rotate(180deg);
}

.search-label {
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  border: none;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  flex-grow: 1;
  outline: none;
}

.search .icon-chev {
  font-size: 24px;
  vertical-align: middle;
  float: none;
}

.dropdown .icon-clean {
  color: #ff6767;
  cursor: pointer;
}

.dropdown-input.dropdown-input-error {
  border-color: #f00;
}

.dropdown-input.dropdown-input-error .icon-chev {
  color: #f00;
}

.dropdown-search-dimension-value {
  min-width: 300px;
  margin-right: 20px;
}
