@import url('edit.css');

.template {
  display: flex;
  flex-direction: column-reverse;
}

.template-item-date {
  text-align: right;
  font-size: 12px;
}

.template-list .modal-section {
  width: 380px;
}

.template-list .modal-close-btn {
  padding: 2px !important;
}

.template-list .options-control-btn {
  height: auto;
  width: auto;
}

.template-list .options {
  height: 30px;
  margin-top: -25px;
}

@media (max-width: 1024px) and (min-width: 768px),
  (max-width: 767px) and (min-width: 481px),
  (max-width: 480px) and (min-width: 0px) {
  .template-item-date {
    text-align: left;
    margin-top: 10px;
  }
}
