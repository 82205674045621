.header-actions {
  display: flex;
  flex-direction: row;
  line-height: 64px;
}

.answer {
  position: relative;
}

.answer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.answer-header-title {
  display: flex;
  align-items: center;
}

.answer-header-title .page-title {
  margin-bottom: 0px;
  vertical-align: middle;
}

.answer-tabs-outer-wrapper {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.answer-tabs-inner-wrapper {
  display: flex;
}

.answer-body {
  position: relative;
}

.answer-actions {
  position: absolute;
  top: 0;
  right: 0;
  color: #585858;
}

.actions-widget {
  background-color: #80ccfd;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 15px;
}

.answer-actions-btn {
  background-color: #80ccfd;
  margin: 0;
  padding: 5px 15px;
  font-size: 20px;
  color: inherit;
  border-right: 1px solid #fff;
}

.answer-actions-btn:first-child {
  border-left: 1px solid #fff;
}

.answer-actions-btn:hover {
  color: #000;
}

.answer-list {
  margin-bottom: 20px;
  width: 100%;
}

.answer-count {
  font-size: 14px;
  color: #585858;
  margin-bottom: 5px;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
  .answer-header {
    display: block;
    text-align: right;
  }
}
