.simple-card {
  margin: 10px;
  text-align: center;
  min-width: 280px;
  border-radius: 12px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 13px 0 var(--black-13);
  background-color: var(--blueLight_50);
}

.simple-card-label {
  font-weight: bold;
  padding: 10px 5px;
  display: block;
  background-color: var(--blueLight_500);
  color: #fff;
  font-size: 18px;
  border-radius: 5px 5px 0px 0px;
}

.simple-card-body {
  min-height: 90px;
}

.simple-card-value {
  display: block;
  font-size: 42px;
  margin-top: 15px;
  font-weight: bold;
}

.simple-card-value-growth {
  font-size: 12px;
  vertical-align: super;
  position: absolute;
}

.simple-card-value-growth svg {
  margin-right: 5px;
}

.simple-card-description {
  font-size: 14px;
  display: block;
  margin-top: 20px;
}

.simple-card-value svg {
  vertical-align: middle;
  margin-left: 10px;
}
