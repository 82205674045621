.dashboard-card-satisfaction-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.dashboard-card-satisfaction {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-card-satisfaction-circle {
  display: flex;
  justify-content: center;
}

.dashboard-card-satisfaction-zone {
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.dashboard-card-satisfaction-percentage {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.dashboard-card-satisfaction-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}
