.dashboard-nocampaign {
  display: flex;
  justify-content: center;
  padding: 0 65px;
  max-width: 1000px;
  margin: 0 auto;
}

.dashboard-nocampaign-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}

.dashboard-nocampaign-text-title {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 10px;
}

.dashboard-nocampaign-text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin-bottom: 20px;
}

.dashboard-nocampaign-text-button {
}

.dashboard-nocampaign-image {
}
