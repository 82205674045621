.form-editor-info .form-field {
  max-width: 340px;
  display: inline-block;
  width: 100%;
  margin-right: 20px;
}

.form-editor-error {
  margin: 5px 5px 10px;
  color: var(--red_900);
  font-weight: bold;
}

.form-editor-info button {
  margin-right: 20px;
}

.form-editor .txt-input {
  max-width: 100%;
  border-radius: 0;
  border: none;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.41);
}

.form-editor .form-field {
  margin-bottom: 20px;
}

.form-editor .txt-field .label {
  color: #757575;
  margin-bottom: 0px;
}

.form-editor-list-no-results {
  margin-top: 15px;
}

.form-editor-item {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 1px 6px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  padding: 10px 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  max-width: 700px;
}

.form-editor-item-custom {
  padding: 0px 0px 10px;
}

.form-editor-item.nopadding {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.form-editor-item-header {
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-editor-item-body {
  padding: 0px 30px;
}

.form-editor-item-drag-icon {
  font-size: 24px;
  color: #dadada;
  cursor: -webkit-grab;
  cursor: grab;
}

.form-editor-line {
  display: flex;
  padding: 20px 20px 20px 32px;
  position: relative;
  flex-flow: column;
}

.form-editor-line:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
}

.form-editor-line-promoter:before {
  background: #60BB46;
}

.form-editor-line-neutral:before {
  background: #F7A723;
}

.form-editor-line-detractor:before {
  background: #EF3E39;
}

.form-editor-line .form-field {
  margin: 0;
}

.form-editor-fields {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}

.form-editor-fields-info {
  background: linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), #F7A723;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.form-editor-line-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-editor-line-actions .MuiFormControlLabel-root {
  margin: 0;
}

.form-editor-line-actions .MuiFormControlLabel-root.Mui-disabled {
  cursor: not-allowed
}

.form-editor-checkbox {
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

.nps-fake-field-circle {
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #00aeef;
  border-radius: 50%;
  margin-bottom: 5px;
}

.nps-fake-field-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.droppable-item {
  cursor: auto !important;
}

.droppable-content-dragging {
  background-color: #f5f5f5;
}

.droppable-content {
  padding: 10px 0px;
}

.droppable-item-dragging {
  background-color: var(--blueLight_50);
  opacity: 0.5;
}

.form-editor-item-custom.droppable-item-dragging {
  height: 90px !important;
  overflow: hidden !important;
}

.form-editor-item-dimension {
  align-items: center;
  display: flex;
}

.conditional-editor {
  margin-top: 25px;
  margin-bottom: 20px;
}

/* Single field array*/

.custom-field-values-editor-field {
  border: none;
  height: 30px;
  background-color: transparent;
}

.custom-field-values-editor-item {
  display: flex;
  align-items: center;
}

.custom-field-values-editor-item:hover .custom-field-values-editor-drag {
  visibility: visible;
}

.custom-field-values-editor-drag {
  font-size: 20px;
  color: #dadada;
  margin-right: 10px;
  visibility: hidden;
  cursor: -webkit-grab;
  cursor: grab;
}

.custom-field-values-editor-arrow {
  color: #dadada;
  margin-right: 5px;
  font-size: 12px;
}

.custom-field-values-editor-add-icon {
  margin-right: 5px;
}

/* TAGS */
.form-editor .react-tags__search {
  width: 100%;
  max-width: 300px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.41);
}

.form-editor .react-tags {
  max-width: 100%;
}

.react-tags.is-focused {
  border-color: transparent;
}

/* Dimension */
.form-editor-adding-dimension .txt-input {
  max-width: 300px;
}

.form-editor-adding-dimension {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-editor-adding-dimension .form-field {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
}

/* Conditional */
.form-editor .react-tags__selected-tag {
  background-color: #e9e9e9;
  color: #000;
  border-radius: 20px;
  padding: 8px 10px;
}

.form-editor .react-tags__selected-tag {
  background-color: #e9e9e9;
  color: #000;
  border-radius: 20px;
  padding: 8px 10px;
}

.conditional-editor-title {
  margin-bottom: 10px;
}

.conditional-editor-area {
  padding: 0px 10px;
}

.conditional-editor-label {
  display: inline-block;
  margin-right: 10px;
}

.conditional-editor-checkbox-group {
  display: inline-block;
}
