.answer-item {
  max-width: calc(100% - 50px);
}

.answer-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.answer-item-summary {
  flex-grow: 1;
  padding: 0px 20px;
}

.answer-item-summary-comment {
  margin-bottom: 5px;
  font-size: 16px;
  color: #212121;
}

.answer-item-summary-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 4px 0;
}

.answer-item-summary-name {
  margin-right: 10px;
  font-size: 14px;
  color: #616161;
}

.answer-item-summary-email {
  margin-right: 10px;
  font-size: 14px;
  color: #616161;
}

.answer-item-summary-right {
  text-align: center;
}

.answer-item-date {
  display: block;
  margin-bottom: 10px;
}

.answer-item-summary-user-wrapper {
  margin-right: 20px;
  margin-bottom: 5px;
}

.answer-item-summary-user-wrapper .answer-item-summary-name {
  margin-right: 0px;
}

.answer-item-summary-user-wrapper .nps-label {
  display: block;
  margin-top: 10px;
  padding: 0px 10px;
  line-height: 24px;
}

.answer-item-summary-nps {
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.answer-item-type {
  color: #616161;
  font-size: 17px;
}

.answer-item-id {
  color: #616161;
  font-size: 12px;
  margin-right: 20px;
}

.answer-item-body {
  width: 100%;
  padding-left: 60px;
  box-sizing: border-box;
}

.answer-item-comment {
  font-size: 16px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 20px;
}

.answer-item-action {
  margin-top: 10px;
}

.answer-item-action > button {
  margin-right: 10px;
}

.answer-item-action > button svg {
  margin-right: 5px;
}

.answer-item-store-comment {
  margin-top: 20px;
}

.store-comment-item-date {
  margin-bottom: 5px;
  display: block;
}

.store-comment-item {
  margin-bottom: 20px;
}

.store-comment-item:last-child {
  margin-bottom: 0px;
}

.btn-answer-tags {
  margin-top: 10px;
}

.answer-tags {
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
}
