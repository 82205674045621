.spreadsheet-preview {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 2px;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  height: 225px;
  overflow: hidden;
  width: 285px;
  max-width: 100%;
  overflow: auto;
}

.preview-header {
  padding: 10px;
}

.preview-header-dimension-set {
  display: flex;
  margin-bottom: 10px;
}

.preview-add-dimension,
.preview-send-dimension {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  line-height: 20px;
  font-size: 18px;
  border-radius: 50%;
  border: 1px solid;
}

.preview-add-dimension svg,
.preview-send-dimension svg {
  vertical-align: text-top;
}

.spreadsheet-preview .form-field {
  margin: 0px;
  min-width: 80%;
}

.spreadsheet-preview .dropdown {
  height: 30px;
  margin: 0;
  border-color: #000;
  flex-grow: 1;
  width: auto;
}

.spreadsheet-preview .search-label {
  padding: 2.5px 10px;
  font-size: 12px;
}

.spreadsheet-preview .search .icon-chev {
  font-size: 18px;
  color: #000;
}

.spreadsheet-preview .dropdown .dropdown-content {
  top: 30px;
  border-color: #000;
}

.preview-header-dimension-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.spreadsheet-preview .txt-field {
  flex-grow: 1;
}

.spreadsheet-preview .loading-text {
  font-size: 12px;
  padding: 10px;
}

.preview-list-item {
  border-top: 1px solid;
  padding: 0px 20px;
  height: 26px;
  box-sizing: content-box;
  line-height: 26px;
}

.spreadsheet-preview .dropdown-content {
  max-height: 150px;
}

.preview-header-import {
  height: 22px;
}

.preview-body {
  font-size: 14px;
}

.preview-header-dimension-new .form-field.text-field {
  width: auto;
}

.preview-body-title {
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}
