.conversion-email-funnel-info {
  padding: 27px 37px;
}

.conversion-email-funnel-info-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  color: #212121;
}

.conversion-email-funnel-info-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.15px;
  color: #666666;
}

.conversion-email-funnel-info-table {
  margin-top: 25px;
}

.conversion-email-funnel-info-table-row {
  display: flex;
  justify-content: space-between;
}

.conversion-email-funnel-info-table-row-label {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}

.conversion-email-funnel-info-table-row-value {
  text-align: start;
  width: 130px;
}

.conversion-email-funnel-info-table-row-value-percentage {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

.conversion-email-funnel-info-table-row-value-total {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.conversion-email-funnel-info-table-row-divider {
  background: #e0e0e0;
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 100%;
  height: 1px;
  margin: 10px 0;
}
