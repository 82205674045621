.blue-item {
  margin: 10px 0px;
  padding: 10px 10px 15px;
  background-color: var(--blueLight_50);
  position: relative;
}

.blue-item-with-aside {
  display: flex;
}

.blue-item-header {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grayUI_500);
  margin-bottom: 10px;
  margin-top: 5px;
  align-items: center;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
  .blue-item-with-aside {
    flex-wrap: wrap;
  }

  .blue-item-header {
    line-height: 1.5;
    word-break: break-all;
  }
}
