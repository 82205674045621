:root {
  --blueLight_200: #7fd6f7;
  --blueLight_100: #99dff9;
  --blueLight_300: #66cef5;
  --blueLight_50: #cceffc;
  --blueLight_400: #33bef2;
  --blueLight_500: #00aeef;
  --blueLight_600: #00a7ed;
  --blueLight_700: #009deb;
  --blueLight_800: #0094e8;
  --blueLight_900: #0084e4;
  --blueDark_50: #ccd9de;
  --blueDark_100: #99b4bd;
  --blueDark_200: #7fa1ac;
  --blueDark_300: #668e9b;
  --blueDark_400: #33697a;
  --blueDark_500: #004359;
  --blueDark_600: #003d51;
  --blueDark_700: #003448;
  --blueDark_800: #002c3e;
  --blueDark_900: #001e2e;
  --green_50: #dff1da;
  --green_100: #bfe4b5;
  --green_200: #afdda2;
  --green_300: #a0d690;
  --green_400: #80c96b;
  --green_500: #60bb46;
  --green_600: #58b53f;
  --green_700: #4eac37;
  --green_800: #44a42f;
  --green_900: #339620;
  --orange_50: #fdedd3;
  --orange_100: #fcdca7;
  --orange_200: #fbd391;
  --orange_300: #faca7b;
  --orange_400: #f9b94f;
  --orange_600: #f69f1f;
  --orange_700: #f5961a;
  --orange_800: #f38c15;
  --orange_900: #f17c0c;
  --red_50: #fcd8d7;
  --red_100: #f9b2b0;
  --red_200: #f79e9c;
  --red_300: #f58b88;
  --red_400: #f26561;
  --red_600: #ed3833;
  --red_700: #eb302c;
  --red_800: #e82824;
  --red_900: #e41b17;
  --grayUI_100: #fafafb;
  --grayUI_200: #f5f6f7;
  --grayUI_400: #dadee2;
  --grayUI_500: #b6bdc4;
  --grayUI_600: #a3adb5;
  --grayUI_700: #919ca7;
  --grayUI_800: #6d7b89;
  --grayUI_900: #485a6c;
  --darkUI_300: #394856;
  --darkUI_400: #323f4b;
  --darkUI_500: #2b3640;
  --darkUI_600: #232d35;
  --darkUI_700: #1c242b;
  --darkUI_800: #151b20;
  --darkUI_900: #0e1215;
  --black: #000000;
  --orange_500: #f7a723;
  --black-13: rgba(0, 0, 0, 0.13);
}
