.modal {
  position: fixed;
  z-index: 9999;
}

.modal-section-parent {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000004f;
}

.modal-section {
  width: 1040px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #707070;
}

.modal-body {
  padding: 0px 30px 15px;
  box-sizing: border-box;
  min-height: 90px;
}

.modal-header {
  text-align: right;
}

.modal-close-btn {
  padding: 5px !important;
  font-size: 24px !important;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 18px;
  color: #353535;
}

.modal-text {
  font-size: 14px;
}
