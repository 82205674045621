.modal-dialog .modal-section {
  width: 640px;
}

.modal-options {
  text-align: right;
  margin-top: 40px;
}

.modal-dialog-btn {
  margin-right: 10px;
}
