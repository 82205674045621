.card {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.campaing-edit-form-type {
  display: flex;
}

.card-title {
  font-size: 21px;
  margin-bottom: 10px;
}

.card-info {
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  color: var(--grayUI_800);
}

.card-info svg {
  font-size: 30px;
  color: var(--blueLight_500);
  margin-left: 10px;
}

.card-alert,
.card-warning {
  font-size: 14px;
  color: var(--orange_900);
  margin-top: 10px;
}

.card-alert {
  color: var(--red_900);
}

.card-actions {
  display: flex;
  justify-content: space-between;
}
