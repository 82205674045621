.conversion-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  color: #212121;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px 0px 0px;
}

.conversion-header-footer {
  margin: 10px 0 15px 0;
}

.conversion-header-footer-campaign {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  color: #212121;
}

.conversion-header-footer-answers {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.conversion-header-footer-answers-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #666666;
  margin-right: 15px;
}

.conversion-header-footer-answers-value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #666666;
  margin-right: 20px;
  margin-left: 5px;
}

.conversion-back {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.conversion-back-text {
  margin-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 141.66%;
  color: #212121;
}
