.confirmation-try-again {
  margin-top: 30px;
  display: block;
  font-size: 18px;
}

.confirmation-text {
  margin-bottom: 10px;
  font-size: 16px;
}
