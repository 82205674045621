.template-edit-body {
  display: flex;
  height: calc(100vh - 280px);
}

.template-edit-preview {
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.template-edit-preview.is-loading {
  padding-top: 50px;
}

.template-edit-preview-filter {
  text-align: center;
  margin-top: 20px;
}

.template-edit-preview-filter svg {
  margin-left: 20px;
}

.template-edit-itens {
  width: 40%;
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
}

.template-edit-blocks {
  overflow-x: hidden;
  padding-top: 5px;
}

.template-block .accordion-item {
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.13),
    0px 1px 3px rgba(0, 0, 0, 0.11);
}

.template-block .accordion-item-btn {
  padding: 20px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 22px;
}

.template-block {
  padding: 0px 10px 15px;
  box-sizing: border-box;
}

.template-block-apply {
  text-align: right;
}

.template-editable {
  margin-bottom: 20px;
}

.template-editable-title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}

.text-editor-wrapper {
  border: 1px solid #f1f1f1;
  padding: 0px 5px 10px;
  box-sizing: border-box;
}

.text-editor-toolbar {
  border: none !important;
}

.text-editor-editor {
  padding: 0px 10px;
}

.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress {
  display: none;
}

.uploadcare--widget {
  margin-left: 10px;
}

.template-edit-header {
  margin-bottom: 10px;
}

.template-edit-form {
  display: flex;
  align-items: center;
}

.template-edit-header .form-field {
  margin-right: 10px;
  display: inline-block;
  min-width: 300px;
  height: 65px;
}

.template-edit-header button {
  margin-right: 10px;
}

.template-edit .txt-input {
  line-height: 25px;
  border-color: rgba(0, 0, 0, 0.41);
}
