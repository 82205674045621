.report-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 16px 16px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15), 0px 3px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}

.report-tooltip-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #9e9e9e;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 6px 0;
}

.report-tooltip-info-wrapper {
  display: flex;
  margin-bottom: 4px;
}

.report-tooltip-info-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #424242;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 2px;
}

.report-tooltip-info-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 2px;
}
