@import url('editor.css');

.form-editor-list .blue-item-header {
  margin-top: 0px;
  padding-bottom: 5px;
  font-size: 16px;
}

.form-editor-list-item-id {
  display: inline-block;
  margin-right: 10px;
}

.form-editor-list-item-action {
  flex-grow: 1;
  text-align: right;
}

.form-editor-list-item-date {
  text-align: right;
  font-size: 12px;
}

.form-tabs-outer-wrapper {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.form-tabs-inner-wrapper {
  display: flex;
}

.form-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
}

.form-no-data-outer-wrapper {
  display: flex;
  justify-content: center;
}

.form-no-data-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 465px;
}

.form-no-data-title {
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px;
}

.form-no-data-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  margin: 8px;
}

.form-no-data-actions {
  margin: 8px;
  display: flex;
  justify-content: center;
}
