.accordion-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.accordion-showing {
  z-index: 5;
  position: relative;
}

.accordion-item-btn {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  width: 100%;
  align-items: center;
  text-align: left;
}

.accordion-item-content {
  padding: 5px 15px;
}
