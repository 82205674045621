.write {
  padding: 30px 10px 10px;
  box-sizing: border-box;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.write-title {
  font-size: 28px;
  font-weight: lighter;
  margin-bottom: 20px;
  text-align: center;
}

.write-body {
  margin: 25px 20px 0px;
}

.write-field-item {
  margin-bottom: 20px;
}

.write-warning {
  text-align: center;
  margin-top: 60px;
}

.write-warning-text {
  margin-bottom: 20px;
}

.write-warning-icon {
  display: inline-block;
  color: var(--green_700);
  font-size: 34px;
  margin-bottom: 10px;
}

.write-error .write-warning-icon {
  color: var(--red_900);
}
