.info-text-wrapper {
  display: flex;
  margin: 10px 0;
}

.info-text-icon {
  color: #9e9e9e;
  height: 35px;
  width: 35px;
}

.info-text-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0px 12px;
}
