.evolution {
  line-height: 18px;
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
}

.evolution-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2px;
  margin-right: 2px;
}

.evolution-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
