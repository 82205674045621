.link-file-name {
  margin-right: 30px;
}

.link-file-name .file-icon {
  margin-right: 5px;
}

.link-file-info {
  margin-bottom: 20px;
}

.link-desc {
  margin-bottom: 20px;
}

.has-errors .spreadsheet-preview {
  border-color: #ca0000;
}

.link-columns-errors {
  margin-bottom: 30px;
  color: #f00;
  font-size: 14px;
  font-weight: bold;
}

.link-people .checkbox-field {
  display: block;
}

.link-checkbox-lists {
  margin-left: 20px;
  max-height: 200px;
  max-width: 300px;
  overflow-y: scroll;
}

.link-checkbox-lists .checkbox-field {
  display: inline-block;
}

.btn.link-continue-btn {
  background-color: #0e83cd;
  color: #fff;
  padding: 10px 40px;
}

.link-columns {
  display: flex;
  position: absolute;
}

.spreadsheet-preview {
  flex-shrink: 0;
}

.link-spreadsheet-data {
  overflow: auto;
  position: relative;
  height: 260px;
  margin-bottom: 10px;
}
