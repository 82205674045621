.color-pic {
  position: relative;
}

.color-pic-modal {
  position: absolute;
  top: 45px;
  left: -5px;
  z-index: 10;
}
