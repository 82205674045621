.person {
  position: relative;
}

.person-actions {
  position: absolute;
  right: 0;
  top: 0;
}

.person-import-btn {
  margin-top: 0px;
}
