.conversion-email-funnel-chart {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.conversion-email-funnel-chart-trapezoid {
  position: relative;
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

.conversion-email-funnel-chart-trapezoid-shape {
  border-radius: 4px;
  position: absolute;
  height: 100%;
}

.conversion-email-funnel-chart-trapezoid-text {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  text-shadow: 0 0 2px #000;
  z-index: 1;
}
