.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
