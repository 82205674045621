.conversion-email-answertable {
  margin-top: 35px;
}

.conversion-email-answertable-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  color: #212121;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.conversion-email-answertable-row {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #212121;
}

.conversion-email-answertable-row-email {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.conversion-email-answertable-row-stage {
  display: flex;
  align-items: center;
}

.conversion-email-answertable-row-stage > * {
  margin-right: 33px;
}

.conversion-email-answertable-row-tooltip {
  white-space: pre-line;
  text-align: center;
}

.conversion-email-answertable-row-status {
  height: 24px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #ffffff;
}

.conversion-email-answertable-row:not(:hover)
  .conversion-email-answertable-row-copy {
  visibility: hidden;
}
