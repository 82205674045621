.conversion-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 390px;
  margin: 0 auto;
}

.conversion-empty-state-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  padding: 20px 0px 5px 0;
}

.conversion-empty-state-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  padding: 10px;
}
