.conversion-email-answertable-dialog {
  padding: 8px;
}

.conversion-email-answertable-dialog-img {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.conversion-email-answertable-dialog-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  text-align: center;
  color: #212121;
}

.conversion-email-answertable-dialog-content {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #212121;
  margin: 20px;
}

.conversion-email-answertable-dialog-actions {
  display: flex;
  justify-content: flex-end;
}

.conversion-email-answertable-dialog-actions > * {
  margin-left: 10px !important;
}
