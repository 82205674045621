.import .accordion-item {
  margin-top: 20px;
}

.import .accordion-item-btn {
  max-width: 300px;
  padding-left: 5px;
}

.import .accordion-item-content {
  padding-left: 5px;
}

.import-steps {
  margin-bottom: 20px;
}

.import-single-step {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 10px;
}

.step-value {
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  margin-right: 10px;

  border: 1px solid #0e83cd;
  background-color: #fff;
  color: #0e83cd;
}

.step-label {
  vertical-align: middle;
  display: inline-block;
}

.import-single-step.active .step-value {
  border: 1px solid transparent;
  background-color: #0e83cd;
  color: #fff;
}
