.filter {
  width: 400px;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;
}

.filter .filter-back-btn {
  font-size: 20px;
}

.filter-body .subtitle {
  margin-top: 13px;
  font-size: 20px;
  margin-bottom: 15px;
}

.filter .datepicker-content {
  width: 280px;
  min-width: auto;
}

.filter-item {
  margin-top: 1px;
}

.filter-item:first-child {
  margin-top: 0px;
}

.filter .datepicker-menu {
  margin: 0px;
  border: none;
}

.filter .datepicker-content {
  right: 0px;
}

.filter .dropdown,
.filter .datepicker {
  margin: 0 !important;
  padding: 0px;
}

.filter .form-field,
.filter .datepicker .form-field.text-field {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.filter-body {
  flex-grow: 1;
  padding-right: 10px;
  box-sizing: border-box;
}

.filter-dimension-autocomplete {
  margin-bottom: 10px;
}

.filter-dimension-chips {
  margin-bottom: 10px;
}

.filter-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.filter .label-title-small {
  margin-bottom: 5px;
  margin-top: 15px;
}
