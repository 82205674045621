.tree-skeleton {
  padding: 0 15px 0 0;
  box-sizing: border-box;
}

.tree-skeleton-scale-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-top: 10px;
}

.tree-skeleton-chart-wrapper {
  margin-bottom: 10px;
}

.tree-skeleton-chart-wrapper > div {
  display: flex;
  height: 50%;
  margin: 2px;
}

.tree-skeleton-chart-wrapper > div > span:first-child {
  margin-right: 2px;
}
