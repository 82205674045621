.options-item-btn {
  margin: 0px;
  width: 100%;
  border-bottom: 1px solid #c7c7c7;
}

.options-item-btn:hover {
  background-color: #c7c7c7;
  opacity: 1;
}

.options-item-btn:last-child {
  border-bottom: none;
}
