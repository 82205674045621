.evolution-wrapper {
  width: 100%;
}

.evolution-chart-wrapper {
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.evolution-chart-wrapper .recharts-layer text {
  font-weight: normal;
  font-size: 12px;
  fill: rgba(0, 0, 0, 0.38);
}

.evolution-chart-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.evolution-chart-title-small {
  font-weight: normal;
  font-size: 12px;
  color: #757575;
}

.evolution-chart-title-big {
  font-weight: normal;
  font-size: 24px;
  color: #000000;
}

.evolution-chart-filters {
  display: flex;
}

.evolution-chart-filters-label {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin-right: 10px;
  align-self: center;
}

.evolution-chart-filters-input {
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #00aeef;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.evolution-chart-filters-input span {
  position: relative;
  top: 1px;
}

.evolution-chart-legend-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.evolution-chart-legend {
  display: flex;
  font-size: 12px;
  color: #757575;
  margin: 5px 10px;
}

.evolution-chart-legend-icon {
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

.evolution-chart-legend-text {
  margin-left: 5px;
}

.evolution-answers-header {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  align-items: center;
  margin-bottom: 10px;
}

.evolution-answers-table {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.evolution-answers-table-item {
  display: flex;
  justify-content: space-between;
  padding: 13px;
}

.evolution-answers-table-item:not(:last-of-type) {
  border-bottom: 1px #e0e0e0 solid;
}

.evolution-answers-table-item-left {
  display: flex;
}

.evolution-answers-table-item-number {
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  margin-right: 10px;
}

.evolution-answers-table-item-message {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  margin-bottom: 8px;
  margin-top: 4px;
}

.evolution-answers-table-item-user {
  display: flex;
  align-items: center;
}

.evolution-answers-table-item-user-name {
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 5px;
}

.evolution-answers-table-item-user-mail {
  font-weight: normal;
  font-size: 12px;
  color: #00aeef;
}

.evolution-answers-table-item-date {
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.54);
  min-width: 135px;
}

.evolution-chart-empty {
  height: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
}

.evolution-answers-empty {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
}
.recharts-rectangle {
  cursor: pointer;
}
