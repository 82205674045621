.btn {
  border: none;
  background-color: #fff;
  padding: 5px 15px;
  margin: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  transition: all ease-out 0.8s;
  outline: 0;
}

.btn:hover {
  opacity: 0.8;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-block {
  display: flex !important;
}

.btn-margin {
  margin: 5px;
}

.btn-margin-top {
  margin-top: 15px;
}

.page-action-header .MuiIconButton-root {
  color: var(--grayUI_900);
}

.btn-page-action {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 28px !important;
  border-radius: 50% !important;
  height: 46px;
  width: 46px;
  padding: 0px !important;
}

.btn-page-action-small {
  font-size: 18px !important;
  height: 32px;
  width: 32px;
}

.btn-page-action:hover {
  color: var(--blueLight_900) !important;
}

.btn-page-action-cancel:hover {
  color: var(--red_900) !important;
}

.btn-big-rounded {
  margin: auto;
  height: 50px;
  line-height: 64px;
  font-size: 15px !important;
  border-radius: 500px !important;
  align-items: center;
}

.btn-big-rounded > svg {
  margin-right: 10px;
  font-size: 24px;
}

.btn-big-full-rounded {
  font-size: 28px !important;
  border-radius: 50% !important;
  height: 46px;
  width: 46px;
  padding: 0px !important;
}
