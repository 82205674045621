@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,400i,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('component/reset.css');
@import url('component/form/button.css');
@import url('component/form/main.css');
@import url('lib/animate.css');
@import url('component/form/tag-autocomplete.css');
@import url('variables.css');
@import url('vendors.css');
@import url('component/item-list/blue.css');
@import url('component/card/main.css');

::-moz-selection {
  background: #33bef2;
}
::selection {
  background: #33bef2;
}

body > iframe {
  pointer-events: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  color: #222;
}

body * {
  outline: 0;
}

body ::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

body ::-webkit-scrollbar-track {
  background: #ddd;
}

body ::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
}

.MuiLinearProgress-colorPrimary {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.app {
  position: relative;
  align-content: baseline;
}

.app-body {
  display: flex;
}

.main {
  margin: 20px 5px 20px 20px;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}

.page-title {
  font-size: 44px;
  text-align: left;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
  color: var(--darkUI_800);
}

.page-description {
  color: var(--grayUI_800);
  font-size: 14px;
  margin-bottom: 20px;
}

.subtitle-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.subtitle-description .subtitle,
.subtitle-description .page-description {
  display: inline-block;
}

.subtitle-description .page-description {
  margin-bottom: 0px;
  margin-left: 20px;
}

.label-title {
  font-size: 18px;
  color: var(--darkUI_800);
}

.label-title-small {
  font-size: 14px;
  font-weight: normal;
}

.MuiChip-root {
  margin-bottom: 3px;
  margin-right: 3px;
  height: auto !important;
  white-space: normal !important;
  line-height: 32px !important;
}

.MuiChip-root.MuiChip-sizeSmall {
  line-height: 24px !important;
}

.MuiChip-label {
  white-space: normal !important;
}

@media (max-width: 1024px) and (min-width: 768px),
  (max-width: 767px) and (min-width: 481px),
  (max-width: 480px) and (min-width: 0px) {
  .main {
    margin-left: 0px;
    padding-left: 10px;
  }
}

.login-input-form {
  margin-bottom: 15px;
}
