.upload-warning {
  margin-bottom: 10px;
  padding-right: 20px;
}

.upload form {
  margin: 20px 0px;
}

.upload .form-field {
  display: block;
}

.upload-zone-file-item {
  display: inline-flex;
  align-items: center;
}

.upload-zone-file-icon svg {
  font-size: 28px;
}

.upload-zone-file-icon {
  margin-right: 10px;
}

.upload-zone {
  border: 1px dashed #7d7d7d;
  padding: 10px;
  cursor: pointer;
}

.upload-zone-label {
  display: block;
  cursor: pointer;
}

.upload-zone-label > svg {
  margin-right: 10px;
  vertical-align: middle;
}

.upload-zone-file {
  margin-top: 20px;
}

.upload-file-error-list {
  margin-top: 15px;
  margin-left: 10px;
}

.upload-file-error {
  color: #f00;
  font-weight: bold;
  font-size: 14px;
}

.btn.upload-btn {
  background-color: #0e83cd;
  color: #fff;
  margin: 20px 0px 0px;
}

.upload-minor-error {
  margin-top: 10px;
  font-size: 17px;
}

.upload-minor-error-btn {
  background-color: #ff0303;
  margin-right: 20px;
  margin-left: 5px;
}
