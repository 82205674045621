.options {
  display: inline-block;
}

.options-active {
  position: relative;
}

.options-modal {
  position: absolute;
  border: 1px solid #c7c7c7;
  box-shadow: 1px 1px 3px 0px #00000052;
  margin-top: -5px;
}

.options-control-btn {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  height: 36px;
  width: 36px;
  padding: 0px !important;
}

.options-control-btn:hover {
  color: var(--blueLight_900) !important;
}

.options-page-action .options-control-btn {
  font-size: 28px !important;
  height: 46px;
  width: 46px;
}
