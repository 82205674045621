.conversion-widget-table {
  margin-top: 10px;
}

.conversion-widget-table-row {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #212121;
}

.conversion-widget-table-row-email {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.conversion-widget-table-row-status {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #ffffff;
}
