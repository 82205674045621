.not-found {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  align-content: center;
}

.not-found-title {
  font-size: 52px;
  width: 100%;
  text-align: center;
  margin-bottom: 14px;
}

.not-found-text {
  text-align: center;
  width: 100%;
}
