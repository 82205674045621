.api {
  margin-top: 5px;
}

.api-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
}

.api-tabs-outer-wrapper {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.api-tabs-inner-wrapper {
  display: flex;
}

.api-no-data-outer-wrapper {
  display: flex;
  justify-content: center;
}

.api-no-data-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 465px;
}

.api-no-data-title {
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px;
}

.api-no-data-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  margin: 8px;
}

.api-no-data-actions {
  margin: 8px;
  display: flex;
  justify-content: center;
}

.api-dialog-content {
  width: 460px;
}

.api-dialog-actions {
  margin-bottom: 5px;
  justify-content: space-between !important;
  padding-left: 20;
}

.api-dialog-actions-buttons > * {
  margin-right: 10px !important;
}

.api-dialog-info {
  display: flex;
  margin: 25px 0 0 0;
}

.api-dialog-info-icon {
  padding: 10px;
}

.api-dialog-info-icon svg {
  color: rgba(0, 0, 0, 0.54);
}

.api-dialog-info-text {
  padding-left: 8px;
}

.api-dialog-info-text-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
}

.api-dialog-info-text-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.api-head th {
  border: 0;
}

.api-head th {
  background: white;
}

.api-status-button {
  opacity: 0;
  transition: opacity 0.3s;
}

.api-row:hover .api-status-button {
  opacity: 1;
}

.api-row td {
  border-bottom-width: 1px;
  border-color: #e0e0e0;
  border-style: solid;
  padding: 11px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 18px;
}

.api-row td:first-child {
  border-left-width: 1px;
}

.api-row td:last-child {
  border-right-width: 1px;
}

.api-row:first-child td {
  border-top-width: 1px;
}

.api-row:first-child td:first-child {
  border-top-left-radius: 4px;
}

.api-row:first-child td:last-child {
  border-top-right-radius: 4px;
}

.api-row:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.api-row:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
