.loading-text {
  width: 100%;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
  cursor: default;
  cursor: wait;
  font-size: 13px;
}

.loading-text-dots {
  animation: extend 1s steps(3, end) infinite;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

.loading-text-dots:before {
  content: '...';
}

@keyframes extend {
  0% {
    width: 0.2em;
  }
  100% {
    width: 0.6em;
  }
}
