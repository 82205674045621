.no-campaign-info {
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 15px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #2196f3;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.no-campaign-info-text {
  margin-left: 15px;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
