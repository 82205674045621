.conversion-email-dispatchtable-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 133.33%;
  color: #212121;
  margin: 25px 0 5px;
}

.conversion-email-dispatchtable-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.15px;
  color: #666666;
  margin-bottom: 5px;
}

.conversion-email-dispatchtable-body {
  display: grid;
}

.conversion-email-dispatchtable-row {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  padding: 27.5px 24px;
}

.conversion-email-dispatchtable-left {
  min-width: 200px;
}

.conversion-email-dispatchtable-left-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.conversion-email-dispatchtable-left-date {
  display: flex;
  justify-content: space-between;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9e9e9e;
}

.conversion-email-dispatchtable-right {
  display: flex;
}

.conversion-email-dispatchtable-right > * {
  display: flex;
  margin: 0 15px;
}

.conversion-email-dispatchtable-right {
  display: flex;
  align-items: center;
}

.conversion-email-dispatchtable-right-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conversion-email-dispatchtable-right-icon-text {
  min-width: 36px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: #212121;
  text-align: center;
}

.conversion-email-dispatchtable-right-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
}
