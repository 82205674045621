.person-item {
  margin: 10px 0px;
  padding: 10px;
  background-color: #c7e9ff;
  position: relative;
}

.person-item.hide {
  opacity: 0.5;
}

.person-item-header {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  align-items: center;
  font-size: 18px;
}

.person-item-id {
  margin-right: 14px;
  font-size: 12px;
  font-style: italic;
}

.person-item-name {
  margin-right: 20px;
}

.person-item-actions {
  position: absolute;
  right: 0;
  top: 0;
}

.person-view-btn {
  padding: 0px;
  font-size: 24px;
  background-color: transparent;
  color: #525252;
}

.person-item-body {
  font-size: 14px;
}

.person-item-list {
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  background-color: #0e83cd;
  border-radius: 5px;
  color: #fff;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
  .person-item-header {
    flex-wrap: wrap;
    max-width: 85%;
    line-height: 1.2;
  }
}
