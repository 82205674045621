.logged-out {
  padding: 30px 0px 0px 60px;
  height: 100vh;
  box-sizing: border-box;
}

.logged-out-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.logged-out-title {
  color: var(--blueLight_500);
  font-size: 50px;
  line-height: 60px;
}

.logged-out-logo {
  margin-right: 30px;
  width: 100px;
}

.logged-out-body {
  display: flex;
  justify-content: space-between;
}

.logged-out-children {
  width: 100%;
}

.sign-form-title {
  color: var(--blueDark_500);
  font-size: 34px;
  font-weight: 900;
  line-height: 40px;
}

.sign-form .checkbox-single-field {
  padding: 20px 0px 5px;
}

.sign-redirect {
  margin-top: 20px;
  color: var(--grayUI_800);
}

.sign-redirect > a {
  color: var(--blueLight_500);
  font-weight: bold;
  text-decoration: none;
  margin-left: 10px;
}

.btn-sign-container {
  margin-top: 30px;
}

.btn-sign {
  line-height: 24px;
  padding: 10px 30px !important;
  margin-top: 30px;
}

@media (max-width: 1024px) and (min-width: 768px),
  (max-width: 767px) and (min-width: 481px),
  (max-width: 480px) and (min-width: 0px) {
  .logged-out-banner {
    display: none;
  }

  .logged-out {
    padding: 10px;
  }
}
