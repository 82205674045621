.dimesion-filters {
  display: flex;
  margin-bottom: 30px;
}

.dimesion-warning {
  text-align: center;
  font-size: 22px;
}

.dimesion-export-btn {
  margin: -30px 10px 0px auto;
  display: block;
  font-size: 24px;
  color: #0e83cd;
  border: 1px solid #0e83cd;
  padding: 3px 10px;
  border-radius: 5px;
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 0px) and (max-width: 480px) {
  .dimesion-filters {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dimesion-filters .datepicker {
    text-align: center;
  }

  .dimesion-export-btn {
    margin: -30px auto 0px auto;
    display: block;
    font-size: 24px;
    color: #0e83cd;
    border: 1px solid #0e83cd;
    padding: 3px 10px;
    border-radius: 5px;
  }
}
