.tree-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}

.tree-wrapper > div {
  width: 100%;
}

.tree-scale-text-outer {
  display: flex;
  justify-content: flex-end;
}

.tree-scale-text-inner {
  width: 33.33%;
  margin-right: calc(13px + 0.2%);
  display: flex;
  justify-content: space-between;
}

.tree-scale-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  position: relative;
  top: 5px;
}
