.label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
}

.white-form .txt-input,
.white-form .number-input,
.white-form .txt-area {
  border-color: #000;
}

.txt-input,
.number-input {
  padding: 5px 10px;
  border: solid 1px #ffffff;
  background-color: #ffffff;
  border-radius: 3px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.number-input {
  max-width: 80px;
}

.number-input-measurement {
  font-size: 14px;
  margin-left: 5px;
}

.input-warning {
  font-size: 10px;
  font-weight: bold;
  color: #f00;
  max-width: 240px;
  display: block;
  margin-top: 3px;
}

.txt-input.txt-input-error,
.number-input.number-input-error {
  border-color: #f00;
}

.txt-area {
  width: 100%;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 5px 10px;
  resize: none;
  max-width: 800px;
}

.txt-area.txt-area-error {
  border-color: #f00;
}

.checkbox-group .checkbox-field {
  display: inline-block;
  margin-right: 10px;
}

.checkbox-group.checkbox-error > span {
  color: #f00;
}

.checkbox-field .label {
  cursor: pointer;
}

.checkbox-input {
  -webkit-appearance: none;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  position: absolute;
}

.checkbox-icon {
  font-size: 18px;
  vertical-align: middle;
}

.checkbox-error .checkbox-field .label,
.checkbox-error.checkbox-field .label,
.checkbox-error.checkbox-field .label > a {
  color: #f00;
}

.checkbox-field .input-warning {
  margin-left: 10px;
}

.date-input .datepicker-menu {
  border-color: #000;
}

.date-field .datepicker .icon-calendar {
  color: #000;
}

.date-input.datepicker {
  justify-content: flex-start;
}

.date-input .datepicker-content {
  right: auto;
  left: 0px;
  border-color: #000;
}

.txt-input-password {
  display: flex;
  padding: 0px;
}

.txt-input-password-hidden {
  border: none;
  width: 100%;
  height: 100%;
  padding: 5px 0px 5px 10px;
  box-sizing: border-box;
  outline: none;
}

.group-star-box {
  display: inline-block;
}

.has-value .field-star {
  color: rgb(250, 250, 73);
}

.group-star-box:hover .field-star,
.group-star-box .field-star:hover {
  color: #ff0;
}

.field-star,
.field-star-checked ~ .field-star,
.field-star:hover ~ .field-star {
  color: var(--grayUI_800);
}

.field-star .checkbox-icon {
  font-size: 32px;
}

.field-star {
  margin: 0px !important;
  padding: 8px 10px 0px 0px;
}

.form-field.checkbox-field {
  margin: 0px !important;
}

.form-field.text-field {
  width: 100%;
  max-width: 400px;
  display: flex;
}

.form-field.noMaxWidth {
  max-width: none;
}

.checkbox-single-field,
.checkbox-group {
  display: flex !important;
}

.checkbox-error p {
  color: #f44336;
}
